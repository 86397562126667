<template>
  <div>
    <a-card>
    <a-result status="error" title="禁止访问" sub-title="当前IP被禁止访问此系统">
      <template #icon>
        <a-icon type="stop" />
      </template>
    </a-result>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'Blocked',
  data() {
    return {
    }
  },
  mounted() {
    // this.request();
  },
  methods: {
  }
}
</script>

<style type="less" scoped>
</style>